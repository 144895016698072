import * as React from 'react';
import { useSelector } from 'react-redux';
import Head from 'next/head';

import config from 'config';
import {
  selectProductSummaries,
  selectPartnershipProductSummaries,
} from 'lib/util/productSummaryShape';
import {
  selectBookingWidgetBannerImageUrls,
  selectBookingWidgetBannerTitle,
  selectBookingWidgetBannerDescription,
  selectBookingWidgetHeaderDescription,
} from 'lib/util/bookingWidgetDesign';
import { ReduxState } from 'ducks';
import { ProductListView } from 'components/ProductList/ProductListView';
import { getSupplierStructuredReviewData } from 'lib/util/getSupplierStructuredReviewData';
import { filterPartnershipProductSummaryShapes } from 'lib/util/filterPartnershipProductSummaryShapes';

export const ProductList = () => {
  const productSummaries = useSelector(selectProductSummaries);
  const partnerProductSummaries = useSelector(selectPartnershipProductSummaries);
  const bannerImageUrls = useSelector(selectBookingWidgetBannerImageUrls);
  const bannerTitle = useSelector(selectBookingWidgetBannerTitle);
  const description = useSelector(selectBookingWidgetBannerDescription);
  const headerDescription = useSelector(selectBookingWidgetHeaderDescription);
  const settings = useSelector((state: ReduxState) => state.server.settings.all);
  const reviewCount = useSelector((state: ReduxState) => state.server.productSummaries.reviewCount);
  const reviewRating = useSelector(
    (state: ReduxState) => state.server.productSummaries.reviewRating
  );

  const filteredPartnerProductSummaries = filterPartnershipProductSummaryShapes(
    partnerProductSummaries,
    settings
  );

  const title = headerDescription || settings.supplier_name;

  const siteName = [headerDescription, settings.supplier_name].join(' ');

  const twitter = (settings?.sns_ids || []).find((snsId) => {
    return snsId?.key === 'twitter';
  });

  const firstBannerImageUrl = bannerImageUrls.length > 0 ? bannerImageUrls[0] : '';

  return (
    <>
      <Head>
        <title>{title}</title>
        {description && (
          <>
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
          </>
        )}
        {headerDescription && <meta property="og:title" content={headerDescription} />}
        {siteName && <meta property="og:site_name" content={siteName} />}
        {firstBannerImageUrl && <meta property="og:image" content={firstBannerImageUrl} />}
        {twitter && twitter?.id && <meta name="twitter:site" content={`@${twitter.id}`} />}
        {reviewCount > 0 && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(
                getSupplierStructuredReviewData(
                  firstBannerImageUrl,
                  settings.supplier_name,
                  reviewRating,
                  reviewCount
                )
              ),
            }}
          />
        )}
      </Head>
      <ProductListView
        title={title}
        supplierName={settings.supplier_name}
        headerDescription={headerDescription}
        logoUrl={settings.logo_url}
        bannerTitle={bannerTitle}
        bannerImageUrls={bannerImageUrls}
        bannerDescription={description}
        productSummaries={[
          ...productSummaries,
          ...(config.enablePartnership ? filteredPartnerProductSummaries : []),
        ]}
      />
    </>
  );
};
